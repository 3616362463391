import React from 'react'

const Footer = props => (
  <footer id="footer">
    <ul className="icons">
      {/* <li>
        <a href="/" className="icon brands alt fa-twitter">
          <span className="label">Twitter</span>
        </a>
      </li> */}
      <li>
        <a href="https://www.facebook.com/Saraiman.arts/" className="icon brands alt fa-facebook-f">
          <span className="label">Facebook</span>
        </a>
      </li>
      {/* <li>
        <a href="/" className="icon brands alt fa-linkedin-in">
          <span className="label">LinkedIn</span>
        </a>
      </li> */}
      {/* <li>
        <a href="/" className="icon brands alt fa-instagram">
          <span className="label">Instagram</span>
        </a>
      </li> */}
      <li>
        <a href="https://youtube.com/c/ȘaraimanArts" className="icon brands alt fa-youtube">
          <span className="label">YouTube</span>
        </a>
      </li>
      <li>
        <a href="mailto:saraimanarts@gmail.com" className="icon solid alt fa-envelope">
          <span className="label">Email</span>
        </a>
      </li>
    </ul>
    <ul className="copyright">
      <li>&copy; 2020 Șaraiman Arts SRL. All rights reserved.</li>
      {/* <li>
        Design: <a href="http://html5up.net">HTML5 UP</a>
      </li> */}
      <li>
        Photos by Shimona Carvalho, Mihai Buzduga, Florin Bondrila, and Mihai Vasiliu.
      </li>
    </ul>
  </footer>
)

export default Footer
